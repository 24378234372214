
import { Vue } from "vue-property-decorator";
import {assetsUrl} from "@/env";

export default Vue.extend( {
  data: () => ({
    cards: [
      { title: 'Pourquoi et comment entraîner les personnes à travailler en équipe ?',
        duration: 12,
        key_word:'compétences collaboratives, activités d\'entrainement...',
        iframe: 'src1',
        pdf: 'pdf1',
        flex: 6,
      },
      { title: 'Pourquoi utiliser la plateforme numérique ECC’IPE ?',
        duration: 12,
        key_word:'interpréter les résultats, calcul des scores, synergie...',
        iframe: 'https://view.genially.com/670ffe2a14ee0bbaf578a141/',
        pdf: 'Module 2 - Pourquoi utiliser la plateforme numérique ECC\'IPE _.pdf',
        flex: 6,
      },
      { title: 'Quels sont les facteurs de réussite d’une équipe ?',
        duration: 12,
        key_word:'répartition de la parole, gestion du conflit, mode de prise de décision...',
        iframe: 'src1',
        pdf: 'pdf1',
        flex: 6,
      },
      { title: 'Comment faire un débriefing ?',
        duration: 12,
        key_word:'étapes du débriefing, feedback de performance et de participation...',
        iframe: 'src1',
        pdf: 'pdf1',
        flex: 6,
      },
    ],
    anim: false,
    nbr_consult: -1,
    iframe_url: '',
  }),

  methods: {
    consulter(index): void {
      console.log("consulter", index);
      this.anim = ! this.anim;
      this.nbr_consult = index;
      console.log("consulter", this.cards[index].iframe);
      this.iframe_url = this.cards[index].iframe;
    },

    retour(): void {
      this.anim = ! this.anim;
    },

    generate_pdf_url(index): void {
      console.log("generate_pdf_url", index);
      return `${assetsUrl}guides/${this.cards[index].pdf}`;
    },

  },

});
